import axios, { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import { FC, PropsWithChildren, useCallback } from 'react';
import { globalAxios } from '../api/api';
import { useAuth } from '../modules/auth';
import { AxiosProvider } from '../modules/axios/AxiosProvider';
import { toast } from '../ui/components/Toast/Toast';
import { useLocalStorage } from 'react-use';
import { getSessionRecodingUrl } from '../integrations';

const AxiosWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { state, logout, getToken, loginSilently } = useAuth();
  const [, setTz] = useLocalStorage('tz');

  const onError = useCallback(
    async (error: AxiosError) => {
      if (window?.location?.pathname?.split('/')[2]?.includes('report')) {
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = error?.response?.data as any;

      if (data?.detail) {
        toast.error(data.detail, {
          toastId: 'toastCustomMessage',
        });
      }

      if (error.config && error.response?.status === 401 && error.config.url.indexOf('login') === -1) {
        try {
          const newToken = await loginSilently();
          if (newToken) {
            return axios.request({
              ...error.config,
              headers: { Authorization: 'Bearer ' + newToken },
            });
          }
        } catch {
          toast.error('Your session expired. Please login again.');
          logout({});
        }
      }

      return Promise.reject(error);
    },
    [logout, loginSilently]
  );

  const onRequest = useCallback(
    async (config) => {
      // For report pages
      if (window?.location?.pathname?.split('/')[2]?.includes('report')) {
        const searchParams = new URLSearchParams(window.location.search);
        const apiKey = searchParams.get('api_key');
        const token = searchParams.get('token');
        const workspace = searchParams.get('workspace');
        if (apiKey) {
          config.headers['x-api-key'] = apiKey;
        }
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        if (workspace) {
          config.params = { ...config.params, workspace };
        }
        config.headers.tz = searchParams.get('tz');
        return config;
      }
      const token = await getToken();
      if (!isEmpty(token)) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (state?.userProfile?.timezone) {
        config.headers.tz = state.userProfile.timezone;
        setTz(state.userProfile.timezone);
      }
      config.headers['X-LogRocket-URL'] = await getSessionRecodingUrl();
      return config;
    },
    [getToken, setTz, state?.userProfile.timezone]
  );

  return (
    <AxiosProvider
      token={state.access}
      axiosInstance={globalAxios}
      onResponseError={onError}
      onRequest={onRequest}>
      {children}
    </AxiosProvider>
  );
};

export default AxiosWrapper;
