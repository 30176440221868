// Newrelic config for NREUM
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NREUMConfig = {} as any;
declare global {
  interface Window {
    newrelic?: {
      setCustomAttribute: (key: string, value: string) => void;
    };
  }
}

NREUMConfig.init = {
  distributed_tracing: { enabled: true },
  privacy: { cookies_enabled: true },
  ajax: { deny_list: ['bam.nr-data.net'] },
};

NREUMConfig.loader_config = {
  agentID: import.meta.env.VITE_NR_APP_ID,
  licenseKey: import.meta.env.VITE_NR_LICENSE_KEY,
  applicationID: import.meta.env.VITE_NR_APP_ID,
};

NREUMConfig.info = {
  beacon: 'bam.nr-data.net',
  errorBeacon: 'bam.nr-data.net',
  licenseKey: import.meta.env.VITE_NR_LICENSE_KEY,
  applicationID: import.meta.env.VITE_NR_APP_ID,
  sa: 1,
};

// Function to set user attributes
export const setNewRelicUserAttributes = (
  name: string,
  email: string,
  organization: string,
  workspace: string
) => {
  if (window.newrelic) {
    window.newrelic.setCustomAttribute('userName', name);
    window.newrelic.setCustomAttribute('userEmail', email);
    window.newrelic.setCustomAttribute('organization', organization);
    window.newrelic.setCustomAttribute('workspace', workspace);
  }
};

export default NREUMConfig;

// Function to clear user attributes
export const clearNewRelicUserAttributes = () => {
  if (window.newrelic) {
    window.newrelic.setCustomAttribute('userName', '');
    window.newrelic.setCustomAttribute('userEmail', '');
    window.newrelic.setCustomAttribute('organization', '');
    window.newrelic.setCustomAttribute('workspace', '');
  }
};
